import React, { Fragment } from "react"
import List from "../../../lists/List"
import TextInput from "../../../TextInput"
import Flex from "../../../Flex"
import Box from "../../../Box"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../../../buttons"
import EntityListItem from "./EntityListItem"
import Typography from "@material-ui/core/Typography"

export default function EntitiesList(props) {

  const entityCompanyIds = props.entityCompanies.map((entityCompany) => entityCompany.company_id)
  const companyIdArray = [...entityCompanyIds, ...props.activeCompanies]

  let companyArray = props.searchedCompanies.length > 0 ? props.searchedCompanies : props.companies
  const showNoResults = props.hasCompanySearch && props.searchedCompanies.length === 0

  return (
    <Fragment>

      <Flex alignItems='center'>
        <Box width={1}>
          <TextInput
            name='companyQuery'
            label='Search Companies'
            value={props.query}
            onChange={props.handleSearchChange}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            helperText='Search by name'
          />
        </Box>

        <Box ml='auto'>
          <RaisedButton onClick={props.handleSearchClick}>
            Search
          </RaisedButton>
        </Box>
      </Flex>

      {showNoResults ? (
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            No companies found matching your search
          </Typography>
        </Box>
      ) : (
        <List>
          {companyArray.map((company) =>
            <EntityListItem
              key={company._source ? company._source.id : company.id}
              company={company._source ? company._source : company}
              checked={companyIdArray.indexOf(company._source ? company._source.id : company.id) == -1 ? false : true}
              handleCheckboxSelect={props.handleCheckboxSelect}
            />
          )}
        </List>
      )}

    </Fragment>
  )
}
