import React from "react"
import PropTypes from "prop-types"
import ListItem from "../lists/ListItem"
import ListItemText from "../lists/ListItemText"
import Checkbox from "../Checkbox"
import Box from "../Box"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

export default function SectorListItem(props) {
  const handleCheckboxSelect = (e) => {
    props.handleCheckboxSelect(props.sector, props.checked)
  }

  const getSectorSource = () => {
    if (!props.checked) return null;
    
    if (props.entitySector?.reason) {
      if (props.entitySector.created_by === 1) {
        return {
          source: "From Product",
          reason: props.entitySector.reason
        }
      } else {
        return {
          source: "AI Recommended",
          reason: props.entitySector.reason
        }
      }
    }
    return {
      source: "Manual Selection",
      reason: null
    }
  }

  const source = getSectorSource()
  const tooltipContent = source ? (
    <div>
      <Typography variant="body2"><strong>Source:</strong> {source.source}</Typography>
      {source.reason && (
        <Typography variant="body2"><strong>Reason:</strong> {source.reason}</Typography>
      )}
    </div>
  ) : null
  
  return(
    <ListItem button key={props.sector.id} role={undefined} onClick={handleCheckboxSelect}>
      <Checkbox 
        disabled={props.disabled}
        checked={props.checked} 
      />
      
      <ListItemText 
        primary={
          <Box display="flex" alignItems="center">
            {tooltipContent ? (
              <Tooltip title={tooltipContent} placement="right" arrow>
                <Typography>{props.sector.abbr}</Typography>
              </Tooltip>
            ) : (
              <Typography>{props.sector.abbr}</Typography>
            )}
          </Box>
        }
      />
    </ListItem>
  )
}

SectorListItem.propTypes = {
  sector: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  handleCheckboxSelect: PropTypes.func.isRequired,
  entitySector: PropTypes.object
}

SectorListItem.defaultProps = {
  disabled: false
}
