import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { RaisedButton, Button } from "../../buttons"
import { List } from "../../lists"
import SectorListItem from "../../sectors/SectorListItem"
import Chip from "@material-ui/core/Chip"
import DoneIcon from "@material-ui/icons/Done"
import Tooltip from "@material-ui/core/Tooltip"
import PersonIcon from '@material-ui/icons/Person'
import Typography from "@material-ui/core/Typography"

export default class ControlPanel extends Component {
  constructor(props) {
    super(props)
  }

  handleReviewedDeleteClick = () => {
    if (this.props.handleReviewedClick) {
      this.props.handleReviewedClick(false)
    }
  }

  getReviewerInfo = () => {
    const { reviewRecord } = this.props;
    if (!reviewRecord) return null;
    
    const { first_name, last_name, reviewed_at } = reviewRecord;
    if (!first_name || !last_name) return null;

    return {
      name: `${first_name} ${last_name}`,
      date: reviewed_at ? new Date(reviewed_at).toLocaleDateString() : null
    }
  }

  render() {
    const reviewer = this.getReviewerInfo();

    return(
      <Fragment>
        <Paper>
          {this.props.handleAssociateClick ?
            <Box width={1} mb={2}>
              <RaisedButton handleClick={this.props.handleAssociateClick} disabled={this.props.disableAssociate}>
                Associate
              </RaisedButton>
            </Box>
          : ""}

          {this.props.handleReviewedClick && (
            <Box width={1} mb={2}>
              {this.props.reviewed ? (
                <Box>
                  <Box mb={1}>
                    <Tooltip title="This patent has been reviewed and verified by an admin" arrow>
                      <Chip
                        icon={<DoneIcon />}
                        label="Reviewed"
                        color="primary"
                        onDelete={this.handleReviewedDeleteClick}
                      />
                    </Tooltip>
                  </Box>
                  {reviewer && (
                    <Box>
                      <Tooltip title={reviewer.date ? `Reviewed on ${reviewer.date}` : "Reviewed by"} arrow>
                        <Chip
                          icon={<PersonIcon />}
                          size="medium"
                          label={`${reviewer.name}`}
                          color="default"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Box mb={1}>
                    <Tooltip title="Mark this patent as reviewed to indicate you've verified its classifications" arrow>
                      <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={() => this.props.handleReviewedClick(true)}
                        fullWidth
                      >
                        Mark as Reviewed
                      </Button>
                    </Tooltip>
                  </Box>
                  {reviewer && (
                    <Box>
                      <Tooltip title={reviewer.date ? `Reviewed on ${reviewer.date}` : "Reviewed"} arrow>
                        <Chip
                          icon={<PersonIcon />}
                          size="medium"
                          label={`Reviewed by ${reviewer.name}`}
                          color="default"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}

          {this.props.mentioned ? <Box width={1} my={3}>
            <RaisedButton color='default' handleClick={this.props.handleAssociateClick}>
              Mentioned
            </RaisedButton>
          </Box> : ''}

          {this.props.handleUnassociateClick ? <Box width={1}>
            <RaisedButton color='default' handleClick={this.props.handleUnassociateClick}>
              Unassociate
            </RaisedButton>
          </Box> : ''}

          {this.props.handleRemoveClick ?
            <Box width={1}>
              <RaisedButton color='default' handleClick={this.props.handleRemoveClick}>
                Remove
              </RaisedButton>
            </Box>
          : ''}

          {this.props.handleAliasCreationClick ? 
            <Box mt={2} width={1}>
              <RaisedButton handleClick={this.props.handleAliasCreationClick} disabled={this.props.associatedCompany ? false : true}>
                Add Assignee as Alias
              </RaisedButton>
            </Box>
          : ''}

          {this.props.sectors ?
            <Box mt={4}>
              <List>
                {this.props.sectors.map((sector) =>
                  <SectorListItem
                    key={sector.id}
                    sector={sector}
                    checked={this.props.entitySectors.length > 0 ? (this.props.entitySectors.filter((entitySector) => entitySector.sector_id == sector.id)[0] ? true : false) : (this.props.checkedSectors && this.props.checkedSectors.length > 0 ? this.props.checkedSectors.filter((checkedSector) => checkedSector == sector.id)[0] : false)}
                    handleCheckboxSelect={this.props.handleSectorCheckboxSelect}
                  />
                )}
              </List>
            </Box>
          : ""}
        </Paper>
      </Fragment>
    )
  }
}

ControlPanel.defaultProps = {
  disableAssociate: false,
  reviewed: false
}

ControlPanel.propTypes = {
  handleAssociateClick: PropTypes.func,
  handleUnassociateClick: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  handleReviewedClick: PropTypes.func,
  handleAliasCreationClick: PropTypes.func,
  handleSectorCheckboxSelect: PropTypes.func,
  disableAssociate: PropTypes.bool,
  reviewed: PropTypes.bool,
  sectors: PropTypes.array,
  entitySectors: PropTypes.array,
  checkedSectors: PropTypes.array,
  associatedCompany: PropTypes.object,
  mentioned: PropTypes.bool
}
