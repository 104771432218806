import React from 'react';
import { 
  Container,
  makeStyles
} from '@material-ui/core';
import Paper from "../Paper";
import Form from "./Form";
import SiteLicensePage from "./SiteLicensePage";
import EdwardsSignupPage from "./EdwardsSignupPage";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
  }
}));

export default function FormContainer({ user, userCompany, authorizedDomain, userCompanySectors }) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Paper className={classes.paper}>

        {userCompany.site_license &&
          <SiteLicensePage
            userCompany={userCompany}
            authorizedDomain={authorizedDomain}
            userCompanySectors={userCompanySectors}
            form={
              <Form 
                user={user}
                userCompany={userCompany}
                authorizedDomain={authorizedDomain}
              />
            }
          />
        }

        {/* {authorizedDomain.user_company_id == 10 &&
          <EdwardsSignupPage
            userCompany={userCompany}
            authorizedDomain={authorizedDomain}
            form={
              <Form 
                user={user}
                userCompany={userCompany}
                authorizedDomain={authorizedDomain}
              />
            }
          />
        } */}
      </Paper>
    </Container>
  );
}

