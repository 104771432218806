import React from 'react';
import AssistantIcon from '@material-ui/icons/Assistant';

function AiIcon(props) {
  return (
    <AssistantIcon {...props} />
  );
}

export default AiIcon;
