import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Link from "../Link"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"
import AbstractsTable from "./AbstractsTable"
import AbstractsChartContainer from "./EntityChartContainer"
import AbstractsChartDataTable from "./EntityChartDataTable"
import Typography from "@material-ui/core/Typography"
import BarChartIcon from '@material-ui/icons/BarChart';
import ListIcon from '@material-ui/icons/List';
import IconButton from "../IconButton"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import FilterChip from '../filters/FilterChip';
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import CloseIcon from "@material-ui/icons/Close"
import { Button } from "../buttons"
import AiMetaSummary from './AiMetaSummary'
import Tooltip from "@material-ui/core/Tooltip"
import AiIcon from '../shared/AiIcon'
import { withSnackbar } from '../SnackbarContainer'

class EntityAbstracts extends Component {
  constructor(props) {
    super(props)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.handleChartViewClick = this.handleChartViewClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getAbstracts = this.getAbstracts.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.state = { abstracts: this.props.abstracts, abstractsCount: this.props.abstracts_count, entity: this.props.entity, entityType: this.props.entity_type, meetings: this.props.meetings, view: 'list', activeIndex: 0, filterValues: { meeting_ids: [], page: this.props.page }, order: this.props.order, orderBy: this.props.order_by, groupedAbstractsData: [], chartData: [], categories: [this.props.categories], showMetaSummary: false }
  }

  componentDidMount() {
    const rootUrl = `/sectors/${this.props.sector.abbr}/${this.state.entityType}/${this.state.entity.id}`

    this.setState({
      rootUrl: rootUrl
    })
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = this.state.orderBy
    params["order"] = this.state.order
    params["page"] = newPage

    this.getAbstracts(params)
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `${this.state.rootUrl}/order_presentations`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          order: params["order"],
          orderBy: params["order_by"],
          abstracts: data.abstracts,
          loading: false
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error. Please try again.', 'error')
        this.setState({
          loading: false
        })
      }
    })
  }

  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleChartViewClick(e) {
    $.ajax({
      type: "GET",
      url: `${this.state.rootUrl}/presentation_chart_data`,
      dataType: "json",
      success: (data) => {
        this.setState({
          view: 'chart',
          chartData: data.chart_data,
          categories: data.categories,
          groupedAbstractsData: data.grouped_abstracts_data
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the data for the chart. Please try again.', 'error')
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getAbstracts(filterValues)
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    this.getAbstracts(params)
  }

  getAbstracts(params) {
    $.ajax({
      type: 'GET',
      url: `${this.state.rootUrl}/presentations`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          abstractsCount: data.abstracts_count,
          meetings: data.meetings,
          filterValues: params
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the data. Please try again.', 'error')
      }
    })
  }

  handleCloseMetaSummary = () => {
    this.setState({ showMetaSummary: false })
  }

  render() {
    const selectedMeetings = this.state.meetings.filter(meeting => this.state.filterValues.meeting_ids.includes(meeting.value) ? meeting : null)
    return(
      <Fragment>

        <Paper fullHeight={this.state.view == 'list' ? true : false}>
          <Box height="100%">

            <Flex mb={2}>
              {this.state.view == 'list' ? <Box width="12%">
                <Select
                  multiple
                  label='Meeting'
                  name='meeting_ids'
                  value={this.state.filterValues.meeting_ids}
                  entities={this.state.meetings}
                  handleChange={this.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedMeetings.length} chosen`}
                    </Flex>
                  )}
                />
              </Box> : ''}

              <Box ml='auto' display="flex" alignItems="center">
                {this.props.meta_summary && this.props.current_user.user_company_id == 1 && (
                  <Tooltip title="AI-Generated Presentations Analysis">
                    <IconButton
                      size="small"
                      onClick={() => this.setState({ showMetaSummary: true })}
                    >
                      <AiIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {this.state.abstracts.length > 0 && (
                  <Fragment>
                    <IconButton onClick={this.handleListViewClick} color={this.state.view == 'list' ? 'primary' : 'default'}>
                      <ListIcon />
                    </IconButton>

                    <IconButton onClick={this.handleChartViewClick} color={this.state.view == 'chart' ? 'primary' : 'default'} tooltip='Chart'>
                      <BarChartIcon />
                    </IconButton>
                  </Fragment>
                )}
              </Box>
            </Flex>

            {this.state.view == 'list' ? <Flex minHeight='28px' alignItems='center'>
              {selectedMeetings.map((activeFilter) =>
                <FilterChip
                  key={activeFilter.value}
                  name={activeFilter.name}
                  value={activeFilter.value}
                  type='meeting_ids'
                  handleDelete={this.handleDelete}
                />
              )}
            </Flex> : ''}

            {this.state.abstracts.length > 0 ? ''
            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No presentations found
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }

            {this.state.view == 'list' && this.state.abstracts.length > 0 ?
              <Fragment>
                <Flex justifyContent='right' pb={2}>
                  <Typography variant="body2">
                    {`${this.state.abstractsCount} Presentations`}
                  </Typography>
                </Flex>

                <AbstractsTable
                  abstracts={this.state.abstracts}
                  abstractsCount={this.state.abstractsCount}
                  page={this.state.filterValues.page}
                  sector={this.props.sector}
                  loading={this.state.loading}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  handleRequestSort={this.handleRequestSort}
                  handleChangePage={this.handleChangePage}
                />
              </Fragment>
            : ''}

            {this.state.view == 'chart' ?
              <Box>
                <Box mb={2}>
                  <AbstractsChartContainer
                    chartTitle={`${this.props.entity_name || this.props.entity.short_name} Presentations`}
                    chartData={this.state.chartData}
                    categories={this.state.categories}
                    groupedAbstractsData={this.state.groupedAbstractsData}
                  />
                </Box>

                <Box textAlign='center'>
                  <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="abstracts chart tabs">
                    <Tab label='Data' />
                  </Tabs>
                </Box>

                <Box>
                  <AbstractsChartDataTable
                    groupedAbstractsData={this.state.groupedAbstractsData}
                    sector={this.props.sector}
                  />
                </Box>

              </Box>
            : ''}

          </Box>

        </Paper>

        {this.props.meta_summary && (
          <Dialog
            open={this.state.showMetaSummary}
            onClose={this.handleCloseMetaSummary}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              <Flex alignItems="center" justifyContent="space-between">
                <Typography variant="h6">{this.props.entity_name || this.props.entity.short_name} Presentations Analysis</Typography>
                <IconButton size="small" onClick={this.handleCloseMetaSummary}>
                  <CloseIcon />
                </IconButton>
              </Flex>
            </DialogTitle>
            <DialogContent>
              <AiMetaSummary metaSummary={this.props.meta_summary} />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseMetaSummary} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

      </Fragment>
    )
  }
}

EntityAbstracts.propTypes = {
  entity: PropTypes.object,
  entity_type: PropTypes.string,
  sector: PropTypes.object,
  abstracts: PropTypes.array,
  abstracts_count: PropTypes.number,
  meetings: PropTypes.array,
  page: PropTypes.number,
  order: PropTypes.string,
  order_by: PropTypes.string,
  meta_summary: PropTypes.object
}

export default withSnackbar(EntityAbstracts)