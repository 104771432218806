import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import { Delete as DeleteIcon, Visibility as VisibilityIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  filters: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    gap: theme.spacing(2)
  },
  formControl: {
    minWidth: 200
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginRight: theme.spacing(1)
  },
  score: {
    fontWeight: 500
  },
  bulkActions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const DATA_TYPES = [
  { label: 'Manuscripts', value: 'manuscripts' },
  { label: 'Trials', value: 'trials' },
  { label: 'News', value: 'news' }
];

const AssociationReview = ({ product, searchTerms, associations: initialAssociations, currentUser }) => {
  const classes = useStyles();
  const [dataType, setDataType] = useState('manuscripts');
  const [selectedSearchTerm, setSelectedSearchTerm] = useState('');
  const [associations, setAssociations] = useState(initialAssociations);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(associations.map(row => row.id));
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter(selectedId => selectedId !== id);
    }

    setSelected(newSelected);
  };

  const handleBulkDelete = () => {
    if (selected.length === 0) return;
    
    if (window.confirm(`Are you sure you want to delete ${selected.length} associations?`)) {
      const deletePromises = selected.map(id => 
        new Promise((resolve, reject) => {
          $.ajax({
            url: `/admin/products/${product.id}/${dataType}/${id}`,
            method: 'PUT',
            data: {
              deleted: true,
              deleted_by: currentUser.id
            },
            dataType: 'json',
            success: resolve,
            error: reject
          });
        })
      );

      Promise.all(deletePromises)
        .then(() => {
          fetchAssociations();
          setSelected([]);
        })
        .catch((error) => {
          console.error('Error deleting associations:', error);
        });
    }
  };

  const fetchAssociations = () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append('data_type', dataType);
    if (selectedSearchTerm) {
      params.append('search_term_id', selectedSearchTerm);
    }
    
    $.ajax({
      url: `/admin/products/${product.id}/association_review?${params.toString()}`,
      method: 'GET',
      dataType: 'json',
      success: (response) => {
        setAssociations(response.associations);
        setSelected([]);
        setLoading(false);
      },
      error: (error) => {
        console.error('Error fetching associations:', error);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchAssociations();
  }, [dataType, selectedSearchTerm]);

  const handleDelete = (associationId) => {
    if (window.confirm('Are you sure you want to delete this association?')) {
      const dataTypeUrl = dataType.slice(0, -1) === 'manuscript' ? 'manuscript_products' : dataType.slice(0, -1) === 'trial' ? 'trial_products' : 'product_news_items';

      const data = {[dataTypeUrl.slice(0, -1)]: {
        deleted: true,
        deleted_by: currentUser.id
      }}

      $.ajax({
        url: `/admin/${dataTypeUrl}/${associationId}`,
        method: 'PUT',
        data: data,
        dataType: 'json',
        success: () => {
          fetchAssociations();
        },
        error: (error) => {
          console.error('Error deleting association:', error);
        }
      });
    }
  };

  const getTypeSpecificColumns = () => {
    switch (dataType) {
      case 'manuscripts':
        return [
          { 
            label: 'Title',
            render: (row) => row?.manuscript?.title || 'N/A'
          },
          {
            label: 'Publication Date',
            render: (row) => row?.manuscript?.publication_date ? 
              new Date(row.manuscript.publication_date).toLocaleDateString() : 'N/A'
          }
        ];
      case 'trials':
        return [
          {
            label: 'Trial Name',
            render: (row) => row?.trial?.name || 'N/A'
          },
          {
            label: 'NCT ID',
            render: (row) => row?.trial?.nct_id || 'N/A'
          }
        ];
      case 'news':
        return [
          {
            label: 'Title',
            render: (row) => row?.news_item?.title || 'N/A'
          },
          {
            label: 'Date',
            render: (row) => row?.news_item?.date ? 
              new Date(row.news_item.date).toLocaleDateString() : 'N/A'
          }
        ];
      default:
        return [];
    }
  };

  const getViewUrl = (row) => {
    switch (dataType) {
      case 'manuscripts':
        return row?.manuscript?.id ? `/admin/publications/${row.manuscript.id}/reviewer` : '#';
      case 'trials':
        return row?.trial?.id ? `/admin/trials/${row.trial.id}` : '#';
      case 'news':
        return row?.news_item?.id ? `/admin/news/${row.news_item.id}` : '#';
      default:
        return '#';
    }
  };

  return (
    <div className={classes.root}>
      <Box className={classes.filters}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Data Type</InputLabel>
          <Select
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
            label="Data Type"
          >
            {DATA_TYPES.map(type => (
              <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Search Term</InputLabel>
          <Select
            value={selectedSearchTerm}
            onChange={(e) => setSelectedSearchTerm(e.target.value)}
            label="Search Term"
          >
            <MenuItem value="">
              <em>All Search Terms</em>
            </MenuItem>
            {searchTerms.map(term => (
              <MenuItem key={term.id} value={term.id}>{term.search_term}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {selected.length > 0 && (
        <Box className={classes.bulkActions}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={handleBulkDelete}
          >
            Delete Selected ({selected.length})
          </Button>
        </Box>
      )}

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < associations.length}
                  checked={associations.length > 0 && selected.length === associations.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              {getTypeSpecificColumns().map((col, index) => (
                <TableCell key={index}>{col.label}</TableCell>
              ))}
              <TableCell>Search Term</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={getTypeSpecificColumns().length + 4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              associations.map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.indexOf(row.id) !== -1}
                      onChange={() => handleSelect(row.id)}
                    />
                  </TableCell>
                  {getTypeSpecificColumns().map((col, index) => (
                    <TableCell key={index}>{col.render(row)}</TableCell>
                  ))}
                  <TableCell>{row.product_search_term?.search_term || 'N/A'}</TableCell>
                  <TableCell className={classes.score}>
                    {row.score ? Number(row.score).toFixed(2) : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.actionButton}
                      component='a'
                      href={getViewUrl(row)}
                      target="_blank"
                      startIcon={<VisibilityIcon />}
                      disabled={getViewUrl(row) === '#'}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AssociationReview; 