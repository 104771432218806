import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Paper from "../../Paper"
import Link from "../../Link"
import Select from "../../Select"
import { RaisedButton, Button } from "../../buttons"
import Snackbar from "../../Snackbar"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import update from "immutability-helper"
import ButtonLayout from "../ButtonLayout"
import Checkbox from "../../Checkbox"
import InlineProductForm from "../products/InlineAddForm"
import CtGovLink from "./CtGovLink"
import Chip from "@material-ui/core/Chip"
import Tooltip from "@material-ui/core/Tooltip"

const SectorChip = ({ sector, score, reasoning, onDelete, isPredicted }) => (
  <Tooltip title={reasoning || "Manually added sector"}>
    <Chip
      label={
        <Flex alignItems="center">
          <Box mr={1}>{sector.name}</Box>
          {score && <Typography variant="caption">({score}%)</Typography>}
        </Flex>
      }
      onDelete={onDelete}
      style={{ 
        margin: '4px',
        backgroundColor: isPredicted ? '#e3f2fd' : '#f5f5f5'
      }}
    />
  </Tooltip>
)

const TypeChip = ({ type, onDelete }) => (
  <Tooltip title="Clinical trial type">
    <Chip
      label={type.name}
      onDelete={onDelete}
      style={{ 
        margin: '4px',
        backgroundColor: '#f5f5f5'
      }}
    />
  </Tooltip>
)

const ProductChip = ({ product, score, reasoning, onDelete, isPredicted, isInvestigatedDevice, onInvestigatedDeviceToggle }) => (
  <Tooltip title={reasoning ? reasoning : "Manually added product"}>
    <Chip
      label={
        <Flex alignItems="center" style={{ cursor: 'pointer' }} onClick={onInvestigatedDeviceToggle}>
          <Box mr={1}>{product.short_name}</Box>
          {score && <Typography variant="caption">({score}%)</Typography>}
          {isInvestigatedDevice && 
            <Box ml={1}>
              <Typography variant="caption" style={{ color: '#2196f3' }}>(Investigated Device)</Typography>
            </Box>
          }
        </Flex>
      }
      onDelete={onDelete}
      style={{ 
        margin: '4px',
        backgroundColor: isPredicted ? '#e3f2fd' : '#f5f5f5',
        cursor: 'pointer'
      }}
    />
  </Tooltip>
)

export default class NewTrial extends Component {
  constructor(props) {
    super(props)
    console.log("Constructor called with props:", props);
    console.log("ct_gov_trial_predicted_products from props:", props.ct_gov_trial_predicted_products);
    
    this.handleChange = this.handleChange.bind(this)
    this.handleCreateClick = this.handleCreateClick.bind(this)
    this.handleHideClick = this.handleHideClick.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.handleSectorAdd = this.handleSectorAdd.bind(this)
    this.handleProductDelete = this.handleProductDelete.bind(this)
    this.handleProductAdd = this.handleProductAdd.bind(this)
    this.handleInvestigatedDeviceToggle = this.handleInvestigatedDeviceToggle.bind(this)
    this.handleTypeAdd = this.handleTypeAdd.bind(this)
    this.handleTypeDelete = this.handleTypeDelete.bind(this)
    
    const initialProducts = this.formatInitialProducts();
    console.log("Initial products after formatting:", initialProducts);
    
    this.state = { 
      type_ids: [], 
      selectedTypeId: '',
      sector_ids: [], 
      showSnackbar: false, 
      snackbarText: '',
      predictedSectors: this.props.ct_gov_trial_sectors || [],
      selectedSectorId: '',
      products: initialProducts,
      selectedProductId: '',
      selectedIsInvestigatedDevice: false
    }
  }

  formatInitialProducts() {
    const predictedProducts = (this.props.predicted_products || [])
      .filter(pp => pp && pp.product) // Filter out entries with missing product data
      .map(pp => {
        return {
          id: pp.id,
          product_id: pp.product.id,
          product: pp.product,
          investigated_device: pp.investigated_device || false,
          ai_score: pp.ai_score,
          reason: pp.reason,
          created_by: this.props.current_user?.id,
          isPredicted: true
        };
      });
    console.log("Formatted products:", predictedProducts);
    return predictedProducts;
  }

  handleChange(name, value) {
    console.log(value)
    this.setState({
      [name]: value
    })
  }

  handleHideClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/ct_gov_trials/${this.props.ctGovTrial.id}`,
      data: {
        ct_gov_trial: {
          hide_trial: 1, relevant: false
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The trial has been hidden"
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false
          })
        }, 5000)
      }
    })
  }

  handleCreateClick(e) {
    if (this.state.type_ids.length === 0) {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You have to add a type to create the trial'
      })
      return
    }
    
    if (this.state.sector_ids.length === 0 && this.state.predictedSectors.length === 0) {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You have to add at least one sector to create the trial'
      })
      return
    }

    const allSectorIds = [
      ...this.state.sector_ids,
      ...this.state.predictedSectors.map(ps => ps.sector.id)
    ]

    $.post("/admin/trials/create_from_ct_gov", { 
      ct_gov_trial_id: this.props.ct_gov_trial.id, 
      type_ids: this.state.type_ids, 
      sector_ids: allSectorIds,
      user_id: this.props.current_user.id, 
      products: this.state.products
    }, (data) => {
      let url = `/admin/trials/${data.trial.id}/edit`
      window.location.href = url
    }, 'json')
  }

  handleSectorAdd(event) {
    const sectorId = event.target.value
    if (!sectorId) return

    const sector = this.props.sectors.find(s => s.value === sectorId)
    if (!sector) return

    if (!this.state.sector_ids.includes(sectorId)) {
      this.setState(prevState => ({
        sector_ids: [...prevState.sector_ids, sectorId],
        selectedSectorId: ''
      }))
    }
  }

  handleSectorDelete(sectorId, isPredicted = false) {
    if (isPredicted) {
      $.ajax({
        type: 'DELETE',
        url: `/admin/ct_gov_trial_sectors/${sectorId}`,
        dataType: 'json',
        success: (data) => {
          const updatedSectors = this.state.predictedSectors.filter(s => s.id !== sectorId)
          this.setState({
            predictedSectors: updatedSectors,
            showSnackbar: true,
            snackbarText: "Sector removed successfully"
          })
        },
        error: () => {
          this.setState({
            showSnackbar: true,
            snackbarText: "Failed to remove sector"
          })
        }
      })
    } else {
      this.setState(prevState => ({
        sector_ids: prevState.sector_ids.filter(id => id !== sectorId)
      }))
    }
  }

  handleProductAdd(event) {
    const productId = event.target.value
    if (!productId) return

    const product = this.props.products.find(p => p.id === productId)
    if (!product) return

    const newProduct = {
      product_id: productId,
      product: {
        id: product.id,
        short_name: product.name  // The dropdown uses 'name' but the chip expects 'short_name'
      },
      investigated_device: this.state.selectedIsInvestigatedDevice,
      created_by: this.props.current_user.id,
      isPredicted: false
    }

    this.setState(prevState => ({
      products: [...prevState.products, newProduct],
      selectedProductId: '',
      selectedIsInvestigatedDevice: false
    }))
  }

  handleProductDelete(productId, isPredicted = false) {
    if (isPredicted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/ct_gov_trial_predicted_products/${productId}`,
        dataType: 'json',
        success: (data) => {
          this.setState(prevState => ({
            products: prevState.products.filter(p => !(p.isPredicted && p.id === productId)),
            showSnackbar: true,
            snackbarText: "Product removed successfully"
          }))
        },
        error: () => {
          this.setState({
            showSnackbar: true,
            snackbarText: "Failed to remove product"
          })
        }
      })
    } else {
      this.setState(prevState => ({
        products: prevState.products.filter(p => !(p.product_id === productId && !p.isPredicted))
      }))
    }
  }

  handleInvestigatedDeviceToggle(productId, isPredicted) {
    this.setState(prevState => ({
      products: prevState.products.map(p => {
        if ((isPredicted && p.id === productId) || (!isPredicted && p.product_id === productId)) {
          const updatedProduct = {
            ...p,
            investigated_device: !p.investigated_device
          };

          // If it's a predicted product, update it on the server
          if (isPredicted) {
            $.ajax({
              type: 'PUT',
              url: `/admin/ct_gov_trial_predicted_products/${productId}`,
              dataType: 'json',
              data: {
                ct_gov_trial_predicted_product: {
                  investigated_device: !p.investigated_device
                }
              },
              dataType: 'json'
            });
          }

          return updatedProduct;
        }
        return p;
      })
    }));
  }

  handleTypeAdd(event) {
    const typeId = event.target.value
    if (!typeId) return

    const type = this.props.clinical_types.find(t => t.value === typeId)
    if (!type) return

    if (!this.state.type_ids.includes(typeId)) {
      this.setState(prevState => ({
        type_ids: [...prevState.type_ids, typeId],
        selectedTypeId: ''
      }))
    }
  }

  handleTypeDelete(typeId) {
    this.setState(prevState => ({
      type_ids: prevState.type_ids.filter(id => id !== typeId)
    }))
  }

  render() {
    // Filter out sectors that are already selected or predicted
    const availableSectors = this.props.sectors.filter(sector => 
      !this.state.sector_ids.includes(sector.value) && 
      !this.state.predictedSectors.some(ps => ps.sector.id === sector.value)
    )

    // Filter out products that are already selected
    const availableProducts = (this.props.products || []).filter(product => 
      !this.state.products.some(p => p.product_id === product.id)
    )

    // Filter out types that are already selected
    const availableTypes = (this.props.clinical_types || []).filter(type => 
      !this.state.type_ids.includes(type.value)
    )

    return(
      <Fragment>

        <Paper>

          <Table>
            <TableHead>
              <TableRow hover={false}>
                <TableCell borderRight={true}>
                  <CtGovLink 
                    nctId={this.props.ct_gov_trial.nct_id}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell borderRight={true}>
                  NCT ID
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.nct_id}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Full Name
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.official_title}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Name
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.brief_title}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Acronym
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.acronym}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Products
                </TableCell>
                <TableCell>
                  <Box mb={2}>
                    <Flex alignItems="center">
                      <Box width="50%" mr={2}>
                        <Select
                          name="selectedProductId"
                          entities={availableProducts.map(p => ({ name: p.name, value: p.id }))}
                          label="Add Product"
                          value={this.state.selectedProductId}
                          handleChange={(name, value) => this.handleProductAdd({ target: { value } })}
                        />
                      </Box>
                      <Box>
                        <Checkbox
                          label="Investigated Device"
                          checked={this.state.selectedIsInvestigatedDevice}
                          onChange={(e) => this.setState({ selectedIsInvestigatedDevice: e.target.checked })}
                        />
                      </Box>
                    </Flex>
                  </Box>
                  <Flex flexWrap="wrap">
                    {this.state.products.map((productData) => (
                      <ProductChip
                        key={`${productData.isPredicted ? 'predicted' : 'manual'}-${productData.isPredicted ? productData.id : productData.product_id}`}
                        product={productData.product}
                        score={productData.ai_score}
                        reasoning={productData.reason}
                        isInvestigatedDevice={productData.investigated_device}
                        onDelete={() => this.handleProductDelete(productData.isPredicted ? productData.id : productData.product_id, productData.isPredicted)}
                        isPredicted={productData.isPredicted}
                        onInvestigatedDeviceToggle={() => this.handleInvestigatedDeviceToggle(productData.isPredicted ? productData.id : productData.product_id, productData.isPredicted)}
                      />
                    ))}
                  </Flex>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Type
                </TableCell>
                <TableCell>
                  <Box mb={2}>
                    <Box width="50%">
                      <Select
                        name="selectedTypeId"
                        entities={availableTypes}
                        label="Add Type"
                        value={this.state.selectedTypeId}
                        handleChange={(name, value) => this.handleTypeAdd({ target: { value } })}
                      />
                    </Box>
                  </Box>
                  <Flex flexWrap="wrap">
                    {this.state.type_ids.map(typeId => {
                      const type = this.props.clinical_types.find(t => t.value === typeId)
                      return type && (
                        <TypeChip
                          key={`type-${typeId}`}
                          type={type}
                          onDelete={() => this.handleTypeDelete(typeId)}
                        />
                      )
                    })}
                  </Flex>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Sectors
                </TableCell>
                <TableCell>
                  <Box mb={2}>
                    <Select
                      name="selectedSectorId"
                      entities={availableSectors}
                      label="Add Sector"
                      value={this.state.selectedSectorId}
                      handleChange={(name, value) => this.handleSectorAdd({ target: { value } })}
                      className="w-50"
                    />
                  </Box>
                  <Flex flexWrap="wrap">
                    {this.state.predictedSectors.map((sectorData) => (
                      <SectorChip
                        key={`predicted-${sectorData.id}`}
                        sector={sectorData.sector}
                        score={sectorData.ai_score}
                        reasoning={sectorData.reason}
                        onDelete={() => this.handleSectorDelete(sectorData.id, true)}
                        isPredicted={true}
                      />
                    ))}
                    {this.state.sector_ids.map((sectorId) => {
                      const sector = this.props.sectors.find(s => s.value === sectorId)
                      return sector && (
                        <SectorChip
                          key={`manual-${sectorId}`}
                          sector={{ name: sector.name }}
                          onDelete={() => this.handleSectorDelete(sectorId)}
                          isPredicted={false}
                        />
                      )
                    })}
                  </Flex>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Brief Summary
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.brief_summary}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Status
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.overall_status}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Sponsor
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.sponsor}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Collaborators
                </TableCell>
                <TableCell>
                  {this.props.collaborators.length > 0 ? this.props.collaborators.map((collaborator) =>
                    <div key={collaborator.id}>
                      {collaborator.name}
                    </div>
                  ) : 'None'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Conditions
                </TableCell>
                <TableCell>
                  {this.props.conditions.length > 0 ? this.props.conditions.map((condition) =>
                    <div key={condition.id}>
                      {condition.name}
                    </div>
                  ) : 'None'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Interventions
                </TableCell>
                <TableCell>
                  {this.props.interventions.length > 0 ? this.props.interventions.map((intervention) =>
                    <div key={intervention.id}>
                      {intervention.name}
                    </div>
                  ) : 'None'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Inclusion Criteria
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial_criteria.inclusion_criteria}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Exclusion Criteria
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial_criteria.exclusion_criteria}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Primary Endpoints
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.primary_outcome}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Secondary Endpoints
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.secondary_outcome}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Detailed Description
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.ct_gov_trial.detailed_description}
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>

          <ButtonLayout>
            <Flex justifyContent="space-between">
              <Box width={1/2}>
                <RaisedButton handleClick={this.handleCreateClick}>
                  Create Trial
                </RaisedButton>
              </Box>
              <Box width={1/3}>
                <RaisedButton color="default" handleClick={this.handleHideClick}>
                  Hide Trial
                </RaisedButton>
              </Box>
            </Flex>
          </ButtonLayout>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
