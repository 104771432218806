import React from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Tabs, Tab, TabPanel } from "../../tabs"
import EnrollmentExtractorForm from "./EnrollmentExtractorForm"
import DateForm from "./DateForm"
import CriteriaForm from "./CriteriaForm"
import EndpointForm from "./EndpointForm"
import StatusForm from "./StatusForm"
import TypeForm from "./TypeForm"
import TextFieldForm from "./TextFieldForm"
import InvestigatorForm from "./InvestigatorForm"
import ConclusionForm from "./ConclusionForm"
import AttributeListItem from "./AttributeListItem"
import List from "../../lists/List"
import NestedStatusForm from "./NestedStatusForm"
import NestedEnrollmentForm from "./NestedEnrollmentForm"
import NestedDataForm from "./NestedDataForm"
import Link from "../../Link"
import { Button, RaisedButton } from "../../buttons"
import TextInput from "../../TextInput"
import Typography from "@material-ui/core/Typography"
import AbstractSummary from "../../abstracts/AbstractSummary"


export default function DataPanel(props) {
  let dataAttributes = ["Status", "Enrollment", "Dates", "Criteria", "Endpoints", "Text fields", "Conclusion"]
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Flex alignItems='center'>
          <Box>
            {props.trial ?
              <Link href={`/sectors/Peripheral/trials/${props.trial.trial_id}`} target="_blank">
                View trial page
              </Link>
            : ''}
          </Box>

          <Box ml='auto'>
            <Button onClick={props.handleAddNoteClick}>
              {props.showNoteToEditor ? 'Hide note' : 'Add note'}
            </Button>
          </Box>
        </Flex>

        {props.showNoteToEditor ?
          <Box my={2}>
            <TextInput
              multiline={true}
              rows={4}
              name='writer_notes'
              label='Notes'
              value={props.abstract.writer_notes}
              onChange={props.handleNoteChange}
              fullWidth
            />

            <Box mt={1}>
              <RaisedButton onClick={props.handleSaveNoteClick}>
                Save note
              </RaisedButton>
            </Box>
          </Box>
        : ''}

        <Box height="100%" overflow='auto'>
          <Box height="80%">
            <List>

              <NestedDataForm attribute='Status' open={props.activeIndex == 0} index={0} handleActiveIndexUpdate={props.handleActiveIndexUpdate} completed={props.trialStatus}>
                <StatusForm
                  trialStatus={props.trialStatus}
                  statuses={props.statuses}
                  currentUser={props.currentUser}
                  handleStatusSaveClick={props.handleStatusSaveClick}
                  handleStatusDeleteClick={props.handleStatusDeleteClick}
                />
              </NestedDataForm>

              <NestedDataForm attribute='Enrollment' open={props.activeIndex == 1} index={1} handleActiveIndexUpdate={props.handleActiveIndexUpdate} completed={props.trialTargetEnrollment || props.trialCurrentEnrollment}>
                <EnrollmentExtractorForm
                  date={props.abstract.date}
                  handleEnrollmentSaveClick={props.handleEnrollmentSaveClick}
                  trialTargetEnrollment={props.trialTargetEnrollment}
                  trialCurrentEnrollment={props.trialCurrentEnrollment}
                />
              </NestedDataForm>

              <NestedDataForm attribute='Dates' open={props.activeIndex == 2} index={2} handleActiveIndexUpdate={props.handleActiveIndexUpdate} completed={props.trialDates.length > 0}>
                <DateForm
                  trialDates={props.trialDates}
                  currentUser={props.currentUser}
                  handleDateSaveClick={props.handleDateSaveClick}
                  handleDateDeleteClick={props.handleDateDeleteClick}
                />
              </NestedDataForm>

              {/* <NestedDataForm attribute='Criterias' open={props.activeIndex == 3} index={3} handleActiveIndexUpdate={props.handleActiveIndexUpdate}  completed={props.trialCriterias.length > 0}>
                <CriteriaForm
                  trialCriterias={props.trialCriterias}
                  currentUser={props.currentUser}
                  handleCriteriaSaveClick={props.handleCriteriaSaveClick}
                  handleCriteriaDeleteClick={props.handleCriteriaDeleteClick}
                />
              </NestedDataForm> */}

              {/* <NestedDataForm attribute='Endpoints' open={props.activeIndex == 4} index={4} handleActiveIndexUpdate={props.handleActiveIndexUpdate} completed={props.trialPrimaryEndpoint || props.trialSecondaryEndpoint}>
                <EndpointForm
                  trialPrimaryEndpoint={props.trialPrimaryEndpoint}
                  trialSecondaryEndpoint={props.trialSecondaryEndpoint}
                  handleEndpointSaveClick={props.handleEndpointSaveClick}
                />
              </NestedDataForm> */}

              {/* <NestedDataForm attribute='Text fields' open={props.activeIndex == 5} index={5} handleActiveIndexUpdate={props.handleActiveIndexUpdate} completed={props.trialTextFields.length > 0}>
                <TextFieldForm
                  trialTextFields={props.trialTextFields}
                  handleTrialTextFieldSaveClick={props.handleTrialTextFieldSaveClick}
                />
              </NestedDataForm> */}

              {/* <NestedDataForm attribute='Investigators' open={props.activeIndex == 6} index={6} handleActiveIndexUpdate={props.handleActiveIndexUpdate} completed={props.trialInvestigators && props.trialInvestigators.length > 0 ? true : false}>
                <InvestigatorForm
                  scrapedNames={props.scrapedNames}
                  trialInvestigators={props.trialInvestigators}
                  handleNameAdd={props.handleNameAdd}
                  handlePersonClick={props.handlePersonClick}
                  handleInvestigatorRemoval={props.handleInvestigatorRemoval}
                  handleNewPerson={props.handleNewPerson}
                />
              </NestedDataForm> */}

              <NestedDataForm attribute='Conclusion' open={props.activeIndex == 7} index={7} handleActiveIndexUpdate={props.handleActiveIndexUpdate} completed={props.abstractConclusion}>
                <ConclusionForm
                  abstractConclusion={props.abstractConclusion}
                  handleConclusionSaveClick={props.handleConclusionSaveClick}
                />
              </NestedDataForm>

              <NestedDataForm attribute='Analysis' open={true} index={8} handleActiveIndexUpdate={props.handleActiveIndexUpdate}>
                <Box mt={3} overflow='auto'>
                  <Box>
                    {props.abstract && props.abstract.summary && (
                      <div>
                        <Typography variant="h6" gutterBottom>
                          AI Generated Summary
                        </Typography>
                        <AbstractSummary summary={props.abstract.summary} />
                      </div>
                    )}
                  </Box>
                </Box>
              </NestedDataForm>
                

            </List>

          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
