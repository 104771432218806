import React, { Fragment } from 'react';
import { 
  Typography,
  makeStyles
} from '@material-ui/core';
import Flex from "../Flex";
import Box from "../Box";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
  },
  topBlock: {
    backgroundColor: '#FBF7ED',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  diagram: {
    maxWidth: '300px',
    width: '100%',
    height: 'auto',
  },
  quote: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: '#F0F5FA',
    padding: theme.spacing(4),
    '&::before': {
      position: 'absolute',
      top: 0,
      left: 0,
      fontSize: '3rem',
      lineHeight: 1,
      color: theme.palette.grey[200],
      fontFamily: 'Georgia, serif',
    }
  },
  quoteText: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(4),
  },
  quoteAuthor: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  contentSpacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    // maxWidth: '600px',
    // margin: '0 auto',
  },
  gridContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionSpacing: {
    marginBottom: theme.spacing(4),
  },
  sectorsList: {
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    margin: `${theme.spacing(1)}px auto ${theme.spacing(2)}px`,
    width: 'fit-content',
    '& li': {
      marginBottom: theme.spacing(0.5),
      position: 'relative',
      paddingLeft: theme.spacing(2),
      '&::before': {
        content: '"•"',
        position: 'absolute',
        left: 0,
        color: theme.palette.text.primary,
      }
    }
  },
  noteText: {
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '0.875rem', // Matches Typography variant="caption"
    color: theme.palette.text.secondary,
  },
  centeredContent: {
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
  },
}));

export default function GoreSignUpPage({ userCompany, authorizedDomain, form, userCompanySectors }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography variant="h4" color="primary" gutterBottom align="center" className={classes.sectionSpacing}>
        {`Welcome ${userCompany.name} employees!`}
      </Typography>

      <Typography variant="h5" gutterBottom className={classes.sectionSpacing}>
        Register here for access to MarketMonitors' CVPipeline services.
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" paragraph className={classes.centeredContent}>
          What is CVPipeline? It's a comprehensive and highly integrated database of product development 
          information. It is designed to help you answer questions about a product's development status, such 
          as when will it reach market, what therapeutic features are likely to be supported by emerging clinical 
          data, and in which regulatory geographies?
        </Typography>
      </Box>

      <div className={classes.sectionSpacing}>
        <Typography variant="body1" gutterBottom>
          {`${userCompany.name} coverage includes the following sectors:`}
        </Typography>
        <ul className={classes.sectorsList}>
          {userCompanySectors.map((sector) => (
            <li key={sector.id}>{sector.abbr}</li>
          ))}
        </ul>
      </div>

      <Typography variant="body1" paragraph>
        Create your account by entering the information below.
      </Typography>

      <div className={classes.sectionSpacing}>
        <Typography className={classes.noteText}>
          Note: new accounts have all of the above sectors enabled. Once you login, you can set your 
          preferences for which sector(s) you want access to and receive alerts about. Go to "settings" 
          (bottom left), "sectors" (top middle), if you want to change your sector preferences.
        </Typography>
      </div>

      <Flex flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <Box width="50%">
          {form}
        </Box>

        <Box width="40%" mt={8}>
          <Typography variant="h5" color="primary" align="center">
            We're excited to have you join the CVPipeline community!
          </Typography>
        </Box>
      </Flex>
    </Fragment>
  );
}

