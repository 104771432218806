import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Divider
} from '@material-ui/core';
import HighlightIcon from '@material-ui/icons/Highlight';
import InlineSearch from '../admin/search/InlineSearch';
import { formatDistanceToNow } from 'date-fns';
import Link from "../Link"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    overflow: 'hidden',
  },
  searchContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  tableContainer: {
    minHeight: 200,
  },
  jobTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  department: {
    marginRight: theme.spacing(1),
  },
  location: {
    backgroundColor: theme.palette.grey[100],
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
  noResults: {
    textAlign: 'center',
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  highlight: {
    backgroundColor: theme.palette.warning.light,
    padding: '0 2px',
  },
  scoreChip: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
  },
  matchedField: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  searchStats: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }
}));

const JobPostingsContainer = ({ companyId, jobPostings, sector, initialJobsCount }) => {
  const classes = useStyles();
  const [jobs, setJobs] = useState(jobPostings);
  const [jobsCount, setJobsCount] = useState(initialJobsCount);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const fetchJobs = (searchQuery = '') => {
    setLoading(true);
    
    $.ajax({
      url: `/sectors/${sector.abbr}/companies/${companyId}/job_postings`,
      method: 'GET',
      data: { query: searchQuery },
      dataType: 'json',
      success: (data) => {
        if (searchQuery) {
          setSearchResults(data);
          setJobs(data.jobs || []);
          setJobsCount(data.jobs_count || 0);
        } else {
          setSearchResults(null);
          setJobs(data.jobs || []);
          setJobsCount(data.jobs_count || 0);
        }
        setLoading(false);
      },
      error: (error) => {
        console.error('Error fetching jobs:', error);
        setJobs([]);
        setJobsCount(0);
        setSearchResults(null);
        setLoading(false);
      }
    });
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearchClick = () => {
    if (query.trim()) {
      fetchJobs(query);
    }
  };

  const handleClearSearchClick = () => {
    setQuery('');
    setSearchResults(null);
    setJobs(jobPostings);
    setJobsCount(jobsCount);
  };

  const renderHighlightedContent = (content, highlights) => {
    if (!highlights) return content;

    const parts = [];
    let lastIndex = 0;

    highlights.forEach((highlight, index) => {
      parts.push(content.substring(lastIndex, highlight.start));
      parts.push(
        <span key={index} className={classes.highlight}>
          {content.substring(highlight.start, highlight.end)}
        </span>
      );
      lastIndex = highlight.end;
    });

    parts.push(content.substring(lastIndex));
    return <>{parts}</>;
  };

  const renderJobRow = (job) => {
    const isSearchResult = searchResults && '_source' in job;
    const jobData = isSearchResult ? job._source : job;
    const highlights = job.highlight;
    const score = job._score;

    return (
      <TableRow key={jobData.id} hover>
        <TableCell>
          <Typography className={classes.jobTitle} variant="subtitle1">
            <Link href={jobData.url} target="_blank">
              {isSearchResult ? renderHighlightedContent(jobData.title, highlights?.title) : jobData.title}
            </Link>
            {/* {score && (
              <Tooltip title="Search relevance score">
                <Chip 
                  size="small" 
                  label={`Score: ${score.toFixed(2)}`}
                  className={classes.scoreChip}
                />
              </Tooltip>
            )} */}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip 
            label={jobData.department || 'N/A'}
            className={classes.department}
            size="small"
          />
        </TableCell>
        <TableCell>
          <Chip
            label={jobData.location || 'N/A'}
            className={classes.location}
            size="small"
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          {jobData.posted_at ? formatDistanceToNow(new Date(jobData.posted_at), { addSuffix: true }) : 'N/A'}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box className={classes.searchContainer}>
          <InlineSearch
            query={query}
            handleQueryChange={handleQueryChange}
            handleSearchClick={handleSearchClick}
            handleClearSearchClick={handleClearSearchClick}
            searchLabel="Search Jobs"
            helperText="Search by title, department, or location"
          />
        </Box>

        {searchResults && (
          <Box className={classes.searchStats}>
            <Typography variant="body2" color="textSecondary">
              Found {jobsCount} matching jobs
            </Typography>
          </Box>
        )}

        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : !jobs.length ? (
          <div className={classes.noResults}>
            <Typography variant="body1">
              {query ? 'No jobs found matching your search criteria' : 'No jobs available at this time'}
            </Typography>
          </div>
        ) : (
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Posted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs.map((job) => renderJobRow(job))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </div>
  );
};

export default JobPostingsContainer; 