import React from "react"
import Typography from "@material-ui/core/Typography"
import { List, ListItemText } from "../lists"
import AiNotice from '../shared/AiNotice';

const AbstractSummary = ({ summary }) => {
  const renderSummaryContent = () => {
    try {
      const summaryData = JSON.parse(summary)
      
      return (
        <div className="w-100">
          <Typography variant="body1" paragraph>
            {summaryData.summary}
          </Typography>

          {summaryData.takeaways && (
            <div className="mb3">
              <Typography variant="h6" gutterBottom>
                Key Takeaways
              </Typography>
              <List>
                {summaryData.takeaways.map((takeaway, index) => (
                  <ListItemText 
                    key={index}
                    primary={takeaway}
                    className="mb2"
                  />
                ))}
              </List>
            </div>
          )}

          {summaryData.actionable_insights && (
            <div className="mb3">
              <Typography variant="h6" gutterBottom>
                Actionable Insights
              </Typography>
              <List>
                {summaryData.actionable_insights.map((insight, index) => (
                  <ListItemText 
                    key={index}
                    primary={insight}
                    className="mb2"
                  />
                ))}
              </List>
            </div>
          )}

          <AiNotice message="This summary was automatically generated using AI to help analyze and synthesize the presentation content. While we strive for accuracy, please refer to the original content for the most precise information." />
        </div>
      )
    } catch (e) {
      // If the summary is not in JSON format, display it as plain text
      return (
        <Typography className='w-100'>
          {summary}
        </Typography>
      )
    }
  }

  return summary ? renderSummaryContent() : null
}

export default AbstractSummary 