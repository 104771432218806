import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '../Box';
import PropTypes from 'prop-types';
import AiIcon from './AiIcon';

const AiNotice = ({ message, style }) => {
  const defaultMessage = "This content was generated using artificial intelligence and may require verification. Please review and validate any information before use.";
  
  return (
    <Typography 
      variant="caption" 
      color="textSecondary"
      component="div"
      style={{ 
        backgroundColor: '#f5f5f5', 
        padding: '8px 12px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '16px',
        borderLeft: '3px solid #e0e0e0',
        ...style
      }}
    >
      <Box mr={1} display="flex" alignItems="center">
        <AiIcon size={20} color="rgba(0, 0, 0, 0.6)" />
      </Box>
      {message || defaultMessage}
    </Typography>
  );
};

AiNotice.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object
};

export default AiNotice; 