import React from "react"
import DeleteButton from "../DeleteButton"
import { ListItemText } from "../../lists"
import ListItemLink from "../../lists/ListItemLink"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"

export default function AssociatedProductListItem(props) {
  const isDeleted = props.isDeleted || false

  function handleClick(e) {
    if (isDeleted) {
      props.handleProductRestoreClick?.(props.publicationProduct)
    } else {
      props.handleProductDeleteClick(props.publicationProduct)
    }
  }

  const getAIRecommendationVariant = () => {
    if (props.publicationProduct.ai_deleted) {
      return "default"
    }
    return "default"
  }

  const getChipStyle = () => {
    if (props.publicationProduct.ai_deleted) {
      return { backgroundColor: '#ef5350', color: 'white' }  // Material red 400
    }
    return { backgroundColor: '#66bb6a', color: 'white' }  // Material green 400
  }

  const getAIRecommendationLabel = () => {
    if (props.publicationProduct.ai_deleted) {
      return "AI Recommended: Delete"
    }
    return "AI Recommended: Keep"
  }

  const getItemStyle = () => {
    if (isDeleted) {
      return { opacity: 0.6 }
    }
    return {}
  }

  return (
    <ListItemLink 
      href={`/admin/products/${props.publicationProduct.product_id}/edit`} 
      target="_blank"
      style={getItemStyle()}
    >
      <ListItemText
        primary={props.publicationProduct.short_name}
        secondary={
          <Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {props.publicationProduct.search_term}
            </Typography>
            <Box mt={1}>
              {props.publicationProduct.reasoning ? (
                <Tooltip 
                  title={`AI Reasoning: ${props.publicationProduct.reasoning}`}
                  placement="top"
                  arrow
                >
                  <Chip
                    size="small"
                    variant={getAIRecommendationVariant()}
                    style={getChipStyle()}
                    label={getAIRecommendationLabel()}
                  />
                </Tooltip>
              ) : (
                <Chip
                  size="small"
                  variant={getAIRecommendationVariant()}
                  style={getChipStyle()}
                  label={getAIRecommendationLabel()}
                />
              )}
            </Box>
          </Box>
        }
      />

      <ListItemSecondaryAction>
        <IconButton onClick={handleClick} size="small">
          {isDeleted ? <AddIcon /> : <DeleteIcon />}
        </IconButton>
      </ListItemSecondaryAction>

    </ListItemLink>
  )
}
