import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import Typography from '@material-ui/core/Typography';
import { List, ListItemText } from '../lists';
import Chip from '@material-ui/core/Chip';
import IconButton from '../IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import { withSnackbar } from '../SnackbarContainer';
import AiNotice from '../shared/AiNotice';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Divider from '@material-ui/core/Divider';

const SectionHeader = ({ title, onCopy }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
    <Typography variant="subtitle2" color="textSecondary">
      {title}
    </Typography>
    <Tooltip title="Copy section">
      <IconButton size="small" onClick={onCopy}>
        <FileCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  </Box>
);

const MetadataItem = ({ children }) => (
  <Typography variant="subtitle2" color="textSecondary" component="span">
    {children}
  </Typography>
);

const MetadataDivider = () => (
  <Divider orientation="vertical" flexItem style={{ margin: '0 12px' }} />
);

const BulletPoint = ({ children, dense }) => (
  <Box display="flex" alignItems="flex-start" mb={dense ? 0.5 : 1}>
    <FiberManualRecordIcon style={{ 
      fontSize: 6, 
      marginRight: 8, 
      marginTop: 8,
      color: 'rgba(0, 0, 0, 0.54)' 
    }} />
    <Typography variant="body1" style={{ flex: 1 }}>
      {children}
    </Typography>
  </Box>
);

const AiMetaSummary = ({ metaSummary, snackbarShowMessage }) => {
  const content = JSON.parse(metaSummary.content);
  const metadata = metaSummary.metadata || {};

  const copyToClipboard = (text, section) => {
    navigator.clipboard.writeText(text).then(
      () => snackbarShowMessage(`${section} copied to clipboard`, 'success'),
      () => snackbarShowMessage('Failed to copy text', 'error')
    );
  };

  const formatListToText = (items) => items.join('\n• ');

  return (
    <Box>
      <Box 
        mb={3} 
        display="flex" 
        alignItems="center" 
        flexWrap="wrap" 
        sx={{
          '& > *': {
            marginTop: 1,
            marginBottom: 1,
          }
        }}
      >
        <MetadataItem>
          Generated on: {new Date(metaSummary.generated_at).toLocaleDateString()}
        </MetadataItem>
        
        <MetadataDivider />
        
        <MetadataItem>
          Based on {metaSummary.source_count} presentations
        </MetadataItem>

        {metadata.date_range_start && metadata.date_range_end && (
          <>
            <MetadataDivider />
            <MetadataItem>
              Analysis period: {metadata.date_range_start === metadata.date_range_end 
                ? new Date(metadata.date_range_start).toLocaleDateString()
                : `${new Date(metadata.date_range_start).toLocaleDateString()} to ${new Date(metadata.date_range_end).toLocaleDateString()}`
              }
            </MetadataItem>
          </>
        )}

        {metadata.meetings?.length > 0 && (
          <>
            <MetadataDivider />
            <MetadataItem>
              Conferences: {metadata.meetings.join(', ')}
            </MetadataItem>
          </>
        )}
      </Box>

      <Box mt={3}>
        <SectionHeader 
          title="Overview" 
          onCopy={() => copyToClipboard(content.executive_summary.overview, 'Overview')} 
        />
        <Typography variant="body1" paragraph>
          {content.executive_summary.overview}
        </Typography>

        <SectionHeader 
          title="Key Points" 
          onCopy={() => copyToClipboard(formatListToText(content.executive_summary.key_points), 'Key Points')} 
        />
        <Box ml={1}>
          {content.executive_summary.key_points.map((point, index) => (
            <BulletPoint key={index} dense>
              {point}
            </BulletPoint>
          ))}
        </Box>
      </Box>

      <Box mt={3}>
        <SectionHeader 
          title="Clinical Performance" 
          onCopy={() => copyToClipboard(formatListToText(content.clinical_performance.key_outcomes), 'Clinical Performance')} 
        />
        <Box ml={1}>
          {content.clinical_performance.key_outcomes.map((outcome, index) => (
            <BulletPoint key={index}>
              {outcome}
            </BulletPoint>
          ))}
        </Box>

        <Box mt={2}>
          <SectionHeader 
            title="Safety Profile" 
            onCopy={() => copyToClipboard(formatListToText(content.clinical_performance.safety_profile), 'Safety Profile')} 
          />
          <Box ml={1}>
            {content.clinical_performance.safety_profile.map((item, index) => (
              <BulletPoint key={index}>
                {item}
              </BulletPoint>
            ))}
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Competitive Position:
        </Typography>
        
        <SectionHeader 
          title="Differentiators" 
          onCopy={() => copyToClipboard(formatListToText(content.competitive_position.differentiators), 'Differentiators')} 
        />
        <Box ml={1}>
          {content.competitive_position.differentiators.map((item, index) => (
            <BulletPoint key={index}>
              {item}
            </BulletPoint>
          ))}
        </Box>

        <Box mt={2}>
          <SectionHeader 
            title="Market Impact" 
            onCopy={() => copyToClipboard(formatListToText(content.competitive_position.market_impact), 'Market Impact')} 
          />
          <Box ml={1}>
            {content.competitive_position.market_impact.map((item, index) => (
              <BulletPoint key={index}>
                {item}
              </BulletPoint>
            ))}
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Progress & Trends:
        </Typography>

        <SectionHeader 
          title="Major Developments" 
          onCopy={() => copyToClipboard(formatListToText(content.temporal_analysis.major_developments), 'Major Developments')} 
        />
        <Box ml={1}>
          {content.temporal_analysis.major_developments.map((dev, index) => (
            <BulletPoint key={index}>
              {dev}
            </BulletPoint>
          ))}
        </Box>

        <Box mt={2}>
          <SectionHeader 
            title="Emerging Trends" 
            onCopy={() => copyToClipboard(formatListToText(content.temporal_analysis.emerging_trends), 'Emerging Trends')} 
          />
          <Box ml={1}>
            {content.temporal_analysis.emerging_trends.map((trend, index) => (
              <BulletPoint key={index}>
                {trend}
              </BulletPoint>
            ))}
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <AiNotice />
      </Box>
    </Box>
  );
};

MetadataItem.propTypes = {
  children: PropTypes.node.isRequired,
};

BulletPoint.propTypes = {
  children: PropTypes.node.isRequired,
  dense: PropTypes.bool,
};

AiMetaSummary.propTypes = {
  metaSummary: PropTypes.shape({
    content: PropTypes.string.isRequired,
    generated_at: PropTypes.string.isRequired,
    source_count: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      date_range_start: PropTypes.string,
      date_range_end: PropTypes.string,
      meetings: PropTypes.arrayOf(PropTypes.string),
      presenters: PropTypes.arrayOf(PropTypes.string)
    })
  }).isRequired,
  snackbarShowMessage: PropTypes.func.isRequired
};

export default withSnackbar(AiMetaSummary); 